import './index.scss'
import Swiper from 'swiper/swiper-bundle.min.js'
import '../../assets/scripts/countNumber.js'

//进场动画

!(function(){
    let html = '<ul>'
    for(let i = 0; i < 10; i++){
        html +='<li style="transition-delay: '+ (30-(i+1)*3) +'s;"></li>'
    }
    html+='</ul>'
    $('.entrance .bg-img').append(html);    

    console.log('window.devicePixelRatio',window.devicePixelRatio)
})()

$(document).ready(function () {
    $('.entrance').addClass('ani1')
    setTimeout(() => {
        $('.entrance').addClass('ani2')
    }, 300);
    setTimeout(() => {
        $('.entrance').addClass('ani3')
    }, 30000);
    setTimeout(() => {
        $('.entrance').addClass('ani4')
    }, 30500);
    setTimeout(() => {
        $('.entrance').addClass('ani6')
    }, 30500);
    setTimeout(() => {
        $('.entrance .cont-m .bg-img .ani-logo1').animate({ opacity: "0" }, 1000);
    }, 32500);
    setTimeout(() => {
        $('.ani-logo2').addClass('ani1')
    }, 32700);
    setTimeout(() => {
        $('.ani-logo2').addClass('ani2')
    }, 33500);
    setTimeout(() => {
        $('.entrance').fadeOut(300)
    }, 33800);
    setTimeout(() => {
        $('.ani-logo2').fadeOut(200)
        bannerswiperinit()
    }, 34300);
});

$('.entrance .jump-ani').on('click',function () {
    $('.entrance').fadeOut(300)
    $('.ani-logo2').fadeOut(300)
    bannerswiperinit()
})


//首页banner图
var screenWidth = window.screen.width;
var indexinterleaveOffset = 0.4; //视差比值
let slides = $('.banner-text .item-text');
function bannerswiperinit(){
    var bannerswiper = new Swiper(".index-banner", {
        direction: "vertical",
        loop: true,
        speed: 800,
        // observer: true,
        // observeParents: true,
        // releaseOnEdges: true,
        // touchReleaseOnEdges: true,
        watchSlidesProgress: true,
        allowTouchMove: false,
        autoplay: {
            delay: 5000,
            stopOnLastSlide: false,
            disableOnInteraction: false,
        },
        pagination: {
            el: ".index-banner .swiper-pagination",
            clickable: true,
        },
        on: {
            init: function (swiper) {
                $(slides[0]).addClass('active');
            },
            transitionStart: function () {
                $(slides).removeClass('active')
            },
            transitionEnd: function () {
                $(slides[this.realIndex]).addClass('active');
            },
            progress: function (swiper) {
                for (var i = 0; i < swiper.slides.length; i++) {
                    var slideProgress = swiper.slides[i].progress;
                    var innerOffset = swiper.width * indexinterleaveOffset;
                    var innerTranslate = slideProgress * innerOffset;
                    swiper.slides[i].querySelector(".slide-inner").style.transform =
                        "translate3d(0," + innerTranslate + "px, 0)";
                }
            },
            touchStart: function (swiper) {
                for (var i = 0; i < swiper.slides.length; i++) {
                    swiper.slides[i].style.transition = "";
                }
            },
            setTransition: function (swiper, speed) {
                for (var i = 0; i < swiper.slides.length; i++) {
                    swiper.slides[i].style.transition = speed + "ms";
                    swiper.slides[i].querySelector(".slide-inner").style.transition =
                        speed + "ms";
                }
            }
        }

    });
}



$(document).scroll(function () {
    var scroH = $(document).scrollTop();
    var viewH = $(window).height();
    var contentH = $(document).height();
    var numScollH = $('.num-cont').offset().top;
    //banner图滚动错位效果
    $('.index-banner .slide-inner b').css({'margin-top':  + scroH / 2 + 'px' });

    //关于我们图片动画效果
    if (scroH > viewH - 400) {
        $('.index-about').addClass('show-img')
    }

    //关于我们数字滚动触发
    if (scroH > numScollH - viewH / 1.3) {
        numScroll()
    }

});

//关于我们数字滚动效果
function numScroll() {
    let num1 = $("#scrollNum1").html()
    let num2 = $("#scrollNum2").html()
    let num3 = $("#scrollNum3").html()
    let num4 = $("#scrollNum4").html()
    let num5 = $("#scrollNum5").html()
    $("#scrollNum1").rollNum({
        deVal: num1
    });
    $("#scrollNum2").rollNum({
        deVal: num2
    });
    $("#scrollNum3").rollNum({
        deVal: num3
    });
    $("#scrollNum4").rollNum({
        deVal: num4
    });
    $("#scrollNum5").rollNum({
        deVal: num5
    });
}

// 解决方案swiper
var solutioninterleaveOffset = 0.6; //视差比值
var solutionSwiper = new Swiper(".index-solution .swiper", {
    speed:800,
    spaceBetween: 0,
    slidesPerView: 1,
    observer: true,
    observeParents: true,
    loop:true,
    // releaseOnEdges: true,
    // touchReleaseOnEdges: true,
    watchSlidesProgress: true,
    autoplay: {
        delay: 3000,
        stopOnLastSlide: false,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
    },
    on: {
        init: function (swiper) {
            $('.index-solution .swiper-text .item-text').eq(0).fadeIn().siblings().fadeOut()
        },
        slideChangeTransitionStart: function () {
            $('.index-solution .swiper-tab .tab p').eq(this.realIndex).addClass('active').siblings().removeClass('active')
            hkScroll(this.realIndex)
            NumScroll(this.realIndex)
            $('.index-solution .swiper-text .item-text').eq(this.realIndex).fadeIn().siblings().fadeOut()
        },
        progress: function (swiper) {
            for (var i = 0; i < swiper.slides.length; i++) {
                var slideProgress = swiper.slides[i].progress;
                var innerOffset = swiper.width * solutioninterleaveOffset;
                var innerTranslate = slideProgress * innerOffset;
                swiper.slides[i].querySelector(".slide-inner").style.transform =
                    "translate3d(" + innerTranslate + "px, 0, 0)";
            }
        },
        touchStart: function (swiper) {
            for (var i = 0; i < swiper.slides.length; i++) {
                swiper.slides[i].style.transition = "";
            }
        },
        setTransition: function (swiper, speed) {
            for (var i = 0; i < swiper.slides.length; i++) {
                swiper.slides[i].style.transition = speed + "ms";
                swiper.slides[i].querySelector(".slide-inner").style.transition =
                    speed + "ms";
            }
        }
    },
});

//解决方案鼠标点击控制swiper切换
$('.index-solution .swiper-tab .tab p').on('click', function () {
    let index = $(this).index()
    $(this).addClass('active').siblings().removeClass('active')
    solutionSwiper.slideTo(index + 1)
})
//解决方案数字滑动
function NumScroll(index) {
    $('.index-solution .swiper-pagination .left-num .cont').css('transform', 'translateY(-' + (100/6)*index + '%)')
}
//解决方案滑块滑动
function hkScroll(index) {
    $('.index-solution .hk').css('transform', 'translateY(' + index*100 +'%)')
}

// 新闻swiper
var newsSwiper = new Swiper(".index-news .swiper", {
    slidesPerView: 1.2,
    spaceBetween: 10,
    navigation: {
        nextEl: ".index-news .swiper-button-next",
        prevEl: ".index-news .swiper-button-prev",
    },
    breakpoints: {
        640: {
            slidesPerView: 2.2,
            spaceBetween: 20,
        },
        768: {
            slidesPerView: 2.5,
            spaceBetween: 20,
        },
        1024: {
            slidesPerView: 3.1,
            spaceBetween: 30,
        },
        1280: {
            slidesPerView: 3.1,
            spaceBetween: 40,
        },
        1366: {
            slidesPerView: 3.6,
            spaceBetween: 40,
        },
        1600: {
            slidesPerView: 4.1,
            spaceBetween: 40,
        },
    },
});